import { MutationTree } from 'vuex';
import { State } from './types';
import { RoofConfiguration, RoofSegment, SolarPanel, BOMOutput } from '@/types';
import { Grid } from '@/plugins/tile-selector/types/Grid';

export const mutations: MutationTree<State> = {
    ADD_CONFIGURATION(state: State, configuration: RoofConfiguration) {
        if (!configuration) {
            return;
        }

        const configurationExists = !!state.configurations.find((item: RoofConfiguration) => item.roofSegment.id === configuration.roofSegment.id);
        if (configurationExists) {
            return;
        }

        Object.freeze(configuration.grid.cells);

        state.configurations.push(configuration);
    },
    REMOVE_CONFIGURATION(state: State, roofSegment: RoofSegment) {
        if (!roofSegment) {
            return;
        }

        const index = state.configurations.findIndex((item: RoofConfiguration) => item.roofSegment.id == roofSegment.id);

        state.configurations.splice(index, 1);
    },
    RESET_DATA(state: State) {
        state.configurations = [];
        state.bom = { materials: [], totalPower: 0 };
    },
    ADD_BOM_DATA(state: State, params: { bomData: { materials: BOMOutput[]; totalPower: number; }}) {
        state.bom = params.bomData;
    },
    REPLACE_PRODUCT(state: State, params: { product: SolarPanel, roofSegment: RoofSegment }) {
        if (!params || !params.product || !params.roofSegment) {
            return;
        }

        const item = state.configurations.find((item: RoofConfiguration) => item.roofSegment.id === params.roofSegment.id);
        if (!item) {
            return;
        }

        item.panel = params.product;
    },
    UPDATE_SELECTION(state: State, params: { roofSegment: RoofSegment, grid: Grid }) {
        if (!params || !params.roofSegment || !params.grid) {
            return;
        }

        const item = state.configurations.find((item: RoofConfiguration) => item.roofSegment.id === params.roofSegment.id);
        if (!item) {
            return;
        }

        item.grid.freezeWatcher = ! item.grid.freezeWatcher;

        // the following was changing dimentions of cells in .grid of TileSelector.vue from mm to pixels :
        //item.grid = params.grid;
        // we are interested only in state of the cell - do not touch dimentions came from BE:
        for (let i = 0; i < params.grid.cells.length; i++)
            for (let j = 0; j < params.grid.cells[i].length; j++) 
                item.grid.cells[i][j].state = params.grid.cells[i][j].state;
        
    },
};
