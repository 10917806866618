<template>
    <LowResolutionView v-if="screenWidth < 1200" />
    <router-view v-else />
</template>

<script>
import LowResolutionView from '@/views/LowResolutionView.vue';

export default {
    name: 'App',
    components: { LowResolutionView },
    data() {
        return {
            englishLocales: ['en', 'eng', 'english'],
            estonianLocales: ['et', 'est', 'esti', 'estonian'],
            screenWidth: window.innerWidth,
        };
    },
    mounted() {
        window.addEventListener('resize', this.updateWidth);

        const {
            currentRoute: {
                value: {
                    query: {
                        lang: locale = '',
                    } = {},
                } = {},
            } = {},
        } = this.$router || {};

        if (!locale || this.$i18n.locale === locale) {
            return;
        }

        if (this.englishLocales.includes(locale.toLowerCase())) {
            this.$i18n.locale = 'en';
            localStorage.setItem('locale', 'en');

            return;
        }

        if (this.estonianLocales.includes(locale.toLowerCase())) {
            this.$i18n.locale = 'et';
            localStorage.setItem('locale', 'et');

            return;
        }
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateWidth);
    },
    methods: {
        updateWidth() {
            this.screenWidth = window.innerWidth;
        },
    },
};
</script>

<style lang='scss'>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>
