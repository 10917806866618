import { GetterTree } from 'vuex';
import { State } from './types';
import { RootState } from '../types';

export const getters: GetterTree<State, RootState> = {
    categories: (state) => {
        return state.categories;
    },
    solarPanelCategories: (state) => {
        return state.categories.filter((category) => category.parent?.slug === 'solar-roofs' || category.parent?.slug === 'solar-walls');
    },
};
