import { GetterTree } from 'vuex';
import { State } from './types';
import { RootState } from '../types';

export const getters: GetterTree<State, RootState> = {
    selectedRoofSegments: (state) => {
        return state.buildingData.roofSegments.filter((roofSegment) => roofSegment.inCart === true);
    },
    highlightedRoofSegments: (state) => {
        return state.buildingData.roofSegments.filter((roofSegment) => roofSegment.selected === true);
    },
    roofSegments: (state) => {
        return state.buildingData.roofSegments;
    },
    totalAreaInCart: (state) => {
        return Math.round(state.buildingData.totalInCartArea3DM2 * 100) / 100;
    },
    totalProductivityKwhInCart: (state) => {
        return Math.round(state.buildingData.totalInCartProductivityKwhPerYear * 100) / 100;
    },
};
