/* eslint-disable @typescript-eslint/no-explicit-any */
import { Point } from '@/plugins/tile-selector/types/Point';

export default {
    transform(data: any) : Point | null {
        if (!data) {
            return null;
        }

        // if (!data.x || !data.y) {
        //     return null;
        // }

        if (Number.isNaN(Number(data.x)) || Number.isNaN(Number(data.y))) {
            return null;
        }

        return {
            x: Number(data.x),
            y: Number(data.y),
        };
    },
};
