import { Address } from '@/types';

let isScriptSetup = false;

export class AddressesService {
    private _autocomplete: google.maps.places.AutocompleteService;

    private static instance: AddressesService | null = null;

    private _service: google.maps.Geocoder;

    constructor() {
        this._autocomplete = new google.maps.places.AutocompleteService();
        this._service = new google.maps.Geocoder();
    }

    public static getInstance(): AddressesService {
        if (!AddressesService.instance) {
            AddressesService.instance = new AddressesService();
        }
        return AddressesService.instance;
    }

    public static loadScript() {
        if (isScriptSetup) {
            return;
        }

        const googleMapScript = document.createElement('SCRIPT');
        const scriptSrc = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_FIREBASE_API_KEY}&libraries=places`;

        googleMapScript.setAttribute('src', scriptSrc);
        googleMapScript.setAttribute('async', '');
        googleMapScript.setAttribute('defer', '');

        document.head.appendChild(googleMapScript);

        isScriptSetup = true;
    }

    public search(input: string): Promise<google.maps.places.AutocompletePrediction[]> {

        if (!input) {
            return Promise.resolve([]);
        }

        return new Promise((resolve) => {
            const req: google.maps.places.AutocompletionRequest = {
                input,
                componentRestrictions: { 'country': ['ee'] },
            };

            this._autocomplete.getPlacePredictions(req, (predictions, status) => {
                if (status !== google.maps.places.PlacesServiceStatus.OK) {
                    console.error(status);
                    resolve([]);

                    return;
                }

                if (!Array.isArray(predictions) || !predictions.length) {
                    resolve([]);

                    return;
                }

                resolve(predictions);
            });
        });
    }

    public getDetails(placeId: string): Promise<Address | null> {
        return new Promise((resolve) => {
            if (!placeId) {
                resolve(null);
                return;
            }

            const req = { placeId } as google.maps.GeocoderRequest;
            this._service.geocode(req, (place, status) => {
                if (status !== google.maps.GeocoderStatus.OK) {
                    console.error(status);

                    resolve(null);
                    return;
                }

                if (!place) {
                    resolve(null);
                    return;
                }

                resolve(this.mapAddressDetails(place[0]));
            });
        });
    }

    private mapAddressDetails(details: google.maps.places.PlaceResult): Address | null {
        if (!details.geometry?.location?.lat || !details.geometry?.location?.lng) {
            return null;
        }

        let streetNumber = '';
        let streetName = '';
        let locality = '';
        let sublocality = '';
        let regionLv1 = '';
        let country = '';
        let postalCode = '';

        if (!Array.isArray(details.address_components) || !details.address_components.length) {
            return null;
        }

        details.address_components.forEach(component => {
            if (component.types.includes('street_number')) {
                streetNumber = component.long_name;
            } else if (component.types.includes('route')) {
                streetName = component.long_name;
            } else if (component.types.includes('locality')) {
                locality = component.long_name;
            } else if (component.types.includes('sublocality_level_1')) {
                sublocality = component.long_name;
            } else if (component.types.includes('administrative_area_level_1')) {
                regionLv1 = component.long_name;
            } else if (component.types.includes('country')) {
                country = component.long_name;
            } else if (component.types.includes('postal_code')) {
                postalCode = component.long_name;
            }
        });

        let placeId = '';
        let name = '';
        let formattedAddress = '';

        if (details.place_id) {
            placeId = details.place_id;
        }

        if (details.name) {
            name = details.name;
        }

        if (details.formatted_address) {
            formattedAddress = details.formatted_address;
        }

        return {
            placeId,
            name,
            lat: details.geometry.location.lat(),
            lng: details.geometry.location.lng(),
            formattedAddress,
            streetNumber,
            streetName,
            locality,
            sublocality,
            regionLv1,
            country,
            postalCode,
        };
    }
}
