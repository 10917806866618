import { MutationTree } from 'vuex';
import { Category } from '@/types';
import { State } from './types';

export const mutations: MutationTree<State> = {
    SET_CATEGORIES(state: State, data: Category[]) {
        state.categories = data;
    },
    RESET_CATEGORIES(state: State) {
        state.categories = [];
    },
};
