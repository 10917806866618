import { ActionTree } from 'vuex';
import { State } from './types';
import { RootState } from '../types';
import { IEnergyProductionService } from '@/services/energy-production/IEnergyProductionService';
import { EnergyProductionServiceFactory } from '@/services/energy-production/EnergyProductionServiceFactory';
import RawDataTransformer from '@/transformers/input/energy-production-data/RawDataTransformer';

import { GridDataResponse, RoofSegment } from '@/types';
import { RawData } from '@/types/energy-production/RawData';

const energyProductionService: IEnergyProductionService =
    EnergyProductionServiceFactory.create();

export const actions: ActionTree<State, RootState> = {
    async fetchProduction({ commit }, params) {
        const production = await energyProductionService.getRawData(params);

        if (production) {
            (production.gridData as GridDataResponse[]).forEach(({ grid}) => {
                Object.freeze(grid.cells);
            });
        }

        commit('SET_RAW_PRODUCTION', production);
    },
    calculateProduction( {commit}, params: { rawData: RawData; segmentsInCart: RoofSegment[] }) {
        let transformedFilteredRawData: RawData | null = null;
        transformedFilteredRawData = RawDataTransformer.transform(params);
        if (!transformedFilteredRawData) return;

        const energyProduction = energyProductionService.calculate(transformedFilteredRawData);
        commit('SET_PRODUCTION_CALCULATION', energyProduction);
    },
    updateProductionGrid({commit}, params) {
        commit('UPDATE_PRODUCTION_GRID', params);
    },
    resetAll({ commit }) {
        commit('RESET_PRODUCTION');
        commit('RESET_RAW_PRODUCTION');
    },
};
