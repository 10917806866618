import { ActionTree } from 'vuex';
import { State } from './types';
import { RootState } from '../types';
import { Category, Product } from '@/types';
import { IProductService } from '@/services/products/IProductService';
import { SolarPanelServiceFactory } from '@/services/products/solar-panels/SolarPanelServiceFactory';

const productService: IProductService<Product> = SolarPanelServiceFactory.create();

export const actions: ActionTree<State, RootState> = {
    async fetchProducts({ commit }, categories: Category[]) {
        const products: Product[] = await productService.getProducts(categories);
        commit('SET_PRODUCTS', products);
    },
    resetAll({ commit }) {
        commit('RESET_PRODUCTS');
    },
};
