import { IProductService } from '../IProductService';
import { Category, Product } from '@/types';
import { generateProducts } from '@/mocks/products/solar-panels/mvp';


export class MockedSolarPanelService implements IProductService<Product> {
    public async getProducts(categories: Category[]): Promise<Product[]> {
        let products: Product[] = [];
        categories.forEach((category: Category) => {
            products = products.concat(generateProducts(category));
        });
        return products;
    }
}
