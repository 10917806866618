import { ActionTree } from 'vuex';
import { State } from './types';
import { RootState } from '../types';
import { CalculationResult, ITileSelectorService } from '@/services/tile-selector/ITileSelectorService';
import { TileSelectorServiceFactory } from '@/services/tile-selector/TileSelectorServiceFactory';
import { RoofConfiguration, RoofSegment, SolarPanel, BOMOutput } from '@/types';
import { Grid } from '@/plugins/tile-selector/types/Grid';

const tileSelectorService: ITileSelectorService = TileSelectorServiceFactory.create();

export const actions: ActionTree<State, RootState> = {
    async addConfiguration({ commit }, params: { product: SolarPanel, roofSegment: RoofSegment }) {
        if (!params || !params.product || !params.roofSegment) {
            return;
        }

        const tileSelectorConfig: CalculationResult = await tileSelectorService.calculateGridData(params);

        if (!tileSelectorConfig) {
            return;
        }

        if (!tileSelectorConfig.grid || !tileSelectorConfig.polygon) {
            return;
        }

        const configuration: RoofConfiguration = {
            roofSegment: params.roofSegment,
            panel: params.product,
            grid: tileSelectorConfig.grid,
            polygon: tileSelectorConfig.polygon,
        };

        commit('ADD_CONFIGURATION', configuration);
    },
    removeConfiguration({ commit }, roofSegment: RoofSegment) {
        if (!roofSegment) {
            return;
        }
        commit('REMOVE_CONFIGURATION', roofSegment);
    },
    resetData({ commit }) {
        commit('RESET_DATA');
    },
    addBOMData({commit}, params: { bomData: { materials: BOMOutput[]; totalPower: number; }}) {
        commit('ADD_BOM_DATA', params);
    },
    replaceProduct({ commit }, params: { product: SolarPanel, roofSegment: RoofSegment }) {
        if (!params || !params.product || !params.roofSegment) {
            return;
        }

        commit('REPLACE_PRODUCT', params);
    },
    updateSelection({ commit }, params: { roofSegment: RoofSegment, grid: Grid }) {
        if (!params || !params.roofSegment || !params.grid) {
            return;
        }

        commit('UPDATE_SELECTION', params);
    },
};
