import { createApp } from 'vue';
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import VueApexCharts from 'vue3-apexcharts';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/tailwind.css';
import i18n from './translations';
import { AddressesService } from './services/maps/AddressesService';
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';


export const firebase = initializeApp({
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY || '',
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN || '',
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID || '',
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET || '',
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID || '',
    appId: process.env.VUE_APP_FIREBASE_APP_ID || '',
});

export const storage = getStorage(firebase);


export const app = createApp(App);

app.use(i18n);
app.use(store);
app.use(router);

app.use(AddressesService.loadScript);
app.use(VueApexCharts);
app.component('MazPhoneNumberInput', MazPhoneNumberInput);

app.mount('#app');
