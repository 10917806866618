export function validateEmail(email: string): boolean {
    if (!email) {
        return true;
    }
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
}

export function capitalize(name: string): string {
    if (!name) {
        return '';
    }

    const capitalizedFirst = name[0].toUpperCase();
    const rest = name.slice(1);

    return capitalizedFirst + rest;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function tileSelectorScale(dimensions: any): number {
    if (!dimensions) {
        return 1;
    }

    const devicePixelRatio = window.devicePixelRatio || 1;
    
    const scaleX = dimensions.canvasWidth / dimensions.gridWidth;
    const scaleY = dimensions.canvasHeight / dimensions.gridHeight;
    const scale = Math.min(scaleX, scaleY) / devicePixelRatio;

    return scale;
}

export function formatNumberByLocale(value: number, fullLocale = 'en-GB'): string {
    return value.toLocaleString(fullLocale);
}

export function getFullLocale(locale: string): string {
    if (locale === 'en') {
        return 'en-GB';
    }

    return 'et-EE';
}

export function getPrivacyPolicyLink(locale: string): string {
    const privacyLink = {
        en: 'https://solarstone.com/data-protection',
        et: 'https://solarstone.com/et/andmekaitsetingimused',
    };
    type langKey = keyof typeof privacyLink;

    const currentLocale = (locale || 'en') as langKey;

    return privacyLink[currentLocale];
}

// convert kebab/snake => camelCase
export function toCamel(s: string) {
    return s.replace(/([-_][a-z])/gi, ($1) => {
        return $1.toUpperCase().replace('-', '').replace('_', '');
    });
}

export function generateHashFromTimestamp() {
    return Math.floor(Math.random() * Date.now()).toString(36);
}

import * as CellStates from '@/plugins/tile-selector/states';
import { Cell } from '@/plugins/tile-selector/types/Cell';
function activeInARow( start: number, wide: number, cells: Cell[]) : boolean {
    let i = 0;

    for (i = start; i < (start + wide); i++) {
        const cellValue = cells[i];
        if (!cellValue) {

            return false;
        }          
        if ((cellValue.state.name != CellStates.ActiveState.name) || (cellValue.type == 'intersection')) {

            return false;
        }
    }

    return true;
}
// if the product is Tile (not SolarPanel) then every cell represents half of tile
// BE does not know anything about tiles and allocates grid by tiles just as small cells 
// fitPanel() filters out active cells that could not be used under panels (6 tiled panel has 12 cell'width)
// same logic is used to drawGridLine() in TileSelector.ts
// fitPanel() needed for calculation of energy production given each cell has Panel's power divided by cell'width (see mvp.ts)
export function fitPanels( tiledBy : number, cells: Cell[], forcePassive: boolean ) {

    let panelTiles = 0;
    let stoneTiles = 0;
    let marginWidth = 0; // shift right to center    
    for (let j = 0; j < cells.length; j ++) {
        let cellValue = cells[j];
        //if (cellValue.state.name == CellStates.ActiveState.name)            cellValue.state = CellStates.PassiveState;
        const nextCellValue = cells[j + 1];
        //if ((row != (this.grid.rows - 2)) // no panels allowed at the roof's lowest row of tiles
        if ((!forcePassive)
            && (!panelTiles && activeInARow(j, tiledBy - panelTiles, cells))) {

            if (!marginWidth) {
                let activeWidth = 0;
                while ((cells[j + activeWidth++].state.name == CellStates.ActiveState.name));
                marginWidth = Math.floor(((activeWidth - 1) % tiledBy) / 2);
                if (marginWidth) marginWidth ++;
            }
            if (marginWidth > 1) { // keep it 1 as indication of shifting done for current length panel-able (wide enough) set of active cells
                cellValue.state = CellStates.PassiveState;
                marginWidth --;
            } else {
                panelTiles ++;
                continue;
            }
        } else if (panelTiles) {
            panelTiles++;
            if (panelTiles < tiledBy) {
                continue;
            }else {                        
                //console.log('j:', j, ' PANEL:', panelTiles, ' row:', i);
                cellValue = cells[j - tiledBy + 1];
                //cellValue.dimensions.height /= 2;
            }
        }else {
            if (
                ((cellValue.state.name != CellStates.NoPanelState.name) || (nextCellValue?.type == 'intersection') ) 
                && (nextCellValue?.state.name != CellStates.NoPanelState.name)) {
                cellValue.state = CellStates.PassiveState;
            }
            marginWidth = 0; // reset for next set of active cells in a row
            if ((j % 2) != 0) {
                //console.log('j:', j, ' continue:', panelTiles, ' row:', i);

                if (!stoneTiles)
                    continue;
                else
                    stoneTiles = 0;
                
            }  else {

                //console.log('j:', j, ' NO:', panelTiles, ' row:', i);
            // second half of the "no solar panel" tile - lets draw it
            }
            
        }
        if (panelTiles && ((j % 2) == 0)) {
            stoneTiles = 1; // first stone after panel could be miss-aligned
        }
        panelTiles = 0;
    }
}