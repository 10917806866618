import { ActionTree } from 'vuex';
import { State } from './types';
import { RootState } from '../types';
import { MapService } from '@/services/maps/MapService';
import { BuildingData, RoofSegment, Address } from '@/types';

const mapService: MapService = MapService.getInstance();

export const actions: ActionTree<State, RootState> = {
    setAddress({ commit }, data: Address) {
        commit('SET_ADDRESS', data);
    },
    setAddressFromMap({ commit }, data: BuildingData) {
        commit('SET_ADDRESS_FROM_MAP', data);
    },
    setBuildingData({ commit }, data: BuildingData) {
        commit('SET_BUILDING_DATA', data);
    },
    highlightSegment(_store, data: RoofSegment) {
        mapService.highlightRoofSegment(data);
    },
    unhighlightSegment(_store, data: RoofSegment) {
        mapService.unhighlightRoofSegment(data);
    },
    selectRoofSegment(_store, data) {
        mapService.selectRoofSegment(data.roofSegment);
    },
    deselectSegment(_store, data) {
        mapService.deselectRoofSegment(data.roofSegment);
    },
    resetAll({ commit }) {
        commit('RESET_ADDRESS');
        commit('RESET_BUILDING_DATA');
    },
    resetAddress({ commit }) {
        commit('RESET_ADDRESS');
    },
    resetMap() {
        mapService.reset();
    },
    setBuildingDataCartId({ commit }) {
        commit('SET_BUILDING_DATA_CART_ID');
    },
    toggleMapIcons() {
        mapService.toggleIcons();
    },
};
