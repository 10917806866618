import { ActionTree } from 'vuex';
import { State } from './types';
import { RootState } from '../types';
import { Category } from '@/types';
import { ICategoryService } from '@/services/categories/ICategoryService';
import { CategoryServiceFactory } from '@/services/categories/CategoryServiceFactory';

const categoryService: ICategoryService = CategoryServiceFactory.create();

export const actions: ActionTree<State, RootState> = {
    async fetchCategories({ commit }) {
        const categories: Category[] = await categoryService.getCategories();
        commit('SET_CATEGORIES', categories);
    },
    resetAll({ commit }) {
        commit('RESET_CATEGORIES');
    },
};
