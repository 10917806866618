import { MutationTree } from 'vuex';
import { SolarPanel } from '@/types';
import { State } from './types';

export const mutations: MutationTree<State> = {
    SET_PRODUCTS(state: State, data: SolarPanel[]) {
        state.solarPanels = data;
    },
    RESET_PRODUCTS(state: State) {
        state.solarPanels = [];
    },
};
