import { MutationTree } from 'vuex';
import { State } from '@/store/user/types';

export const mutations: MutationTree<State> = {
    SET_EMAIL(state: State, email: string) {
        state.email = email;
    },
    SET_PHONE(state: State, phone: string) {
        state.phone = phone;
    },
    SET_FIRST_NAME(state: State, firstName: string) {
        state.firstName = firstName;
    },
    SET_LAST_NAME(state: State, lastName: string) {
        state.lastName = lastName;
    },
    SET_BETA_DISCLAIMER(state: State, betaDisclaimer: boolean) {
        state.betaDisclaimer = betaDisclaimer;
    },
    RESET_USER_STATE(state: State) {
        state.email = '';
        state.phone = '';
    },
};
