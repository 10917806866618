/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from '@/plugins/tile-selector/types/Grid';
import { Cell } from '@/plugins/tile-selector/types/Cell';
import CellTransformer from './CellTransformer';


function isPositiveInteger(number: any): boolean {
    return Number.isInteger(number) && number > 0;
}

import { fitPanels } from '@/utils';

export default {
    transform(data: any): Grid | null {
        if (!data) {
            return null;
        }

        if (!data.rows || !data.columns) {
            return null;
        }

        if (!isPositiveInteger(data.rows) || !isPositiveInteger(data.columns)) {
            return null;
        }

        if (!data.cellWidth || !data.cellHeight) {
            return null;
        }

        if (
            !isPositiveInteger(data.cellWidth) ||
            !isPositiveInteger(data.cellHeight)
        ) {
            return null;
        }

        if (!Array.isArray(data.cells) || !data.cells.length) {
            return null;
        }

        const tilesInPanel = (data.tiledBy) ? Number(data.tiledBy) : 0;

        const cells: Cell[][] = [];
        for (let i = 0; i < data.rows; i++) {
            if (!Array.isArray(data.cells[i]) || !data.cells[i].length) {
                return null;
            }

            const gridRow: Cell[] = [];
            for (let j = 0; j < data.columns; j++) {
                const transformed = CellTransformer.transform(data.cells[i][j]);
                if (!transformed) {
                    return null;
                }

                gridRow.push(transformed);
            }

            if (tilesInPanel) {

                fitPanels(tilesInPanel, gridRow, i == (cells.length - 2)); // second lowest row should have no panels
            }


            cells.push(gridRow);
        }

        if (!cells.length) {
            return null;
        }

        return {
            rows: Number(data.rows),
            columns: Number(data.columns),
            cellWidth: Number(data.cellWidth),
            cellHeight: Number(data.cellHeight),
            tiledBy: tilesInPanel,
            cells: cells,
        };
    },
};
