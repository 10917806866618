import { Category } from '@/types';
import { SolarPanel, Tile, Product } from '@/types';

export function generateProducts(category: Category): Product[] {
    
    const fullRoofBlackPreviewImage =
        'https://p01--ss-backend--8b99jtl4bvsp.code.run/uploads/black.png';

    if (category.slug == 'tiled-roof') {
        const result: Tile[] = [];
        result.push(
            {
                id: '801' + '-from-' + category.id,
                name: 'Carisma',
                code: 'Benders kood',
                price: 202,
                passivePrice: 202, // TODO: remoe this from tiled
                halvedPrice: 202, // TODO: remoe this from tiled
                category,
                manufacturer: 'Benders',
                width: 250 / 2, // one cell is half of a tile 
                height: 345,
                color: 'black',
                previewImage: fullRoofBlackPreviewImage,
                images: [],
                compatible: [
                    {
                        id: '891' + '-from-' + category.id,
                        name: 'Solarstone 90W',
                        code: 'STR30BE90WC18',
                        price: 115,
                        passivePrice: 115, // TODO: remoe this from tiled
                        halvedPrice: 115, // TODO: remoe this from tiled
                        category,                        
                        manufacturer: 'Solarstone',
                        widthInTiles: 6 * 2, // one tile consists of two cells
                        power: 90,
                        color: 'black',                        
                    },
                ],
                panel: null,
                power: 90 / (6 * 2) , // the power of ONE cell (not one panel)
            },
            // ===========================================================================            
            {
                id: '802' + '-from-' + category.id,
                name: 'Turmalin',
                code: 'BMI kood',
                price: 202,
                passivePrice: 202, // TODO: remoe this from tiled
                halvedPrice: 202, // TODO: remoe this from tiled                
                category,
                manufacturer: 'BMI Monier ',
                width: 240 / 2,
                height: 370,
                color: 'black',
                previewImage: fullRoofBlackPreviewImage,
                images: [],
                compatible: [
                    {
                        id: '892' + '-from-' + category.id,
                        name: 'Solarstone 108W',
                        code: 'STR30TU108WC22',
                        price: 115,
                        passivePrice: 115, // TODO: remoe this from tiled
                        halvedPrice: 115, // TODO: remoe this from tiled                        
                        category,                        
                        manufacturer: 'Solarstone',
                        widthInTiles: 7.5 * 2,
                        power: 108,
                        color: 'red',                        
                    },
                ],
                panel: null,
                power: 108/ (7.5 * 2),
            }, 
            // ===========================================================================            
            {
                id: '803' + '-from-' + category.id,
                name: 'Evo',
                code: 'BMI kood',
                price: 202,
                passivePrice: 202, // TODO: remoe this from tiled
                halvedPrice: 202, // TODO: remoe this from tiled                
                category,
                manufacturer: 'BMI Monier ',
                width: 298 / 2,
                height: 335,
                color: 'black',
                previewImage: fullRoofBlackPreviewImage,
                images: [],
                compatible: [
                    {
                        id: '894' + '-from-' + category.id,
                        name: 'Solarstone 90W',
                        code: 'STR21MO90WC18',
                        price: 115,
                        passivePrice: 115, // TODO: remoe this from tiled
                        halvedPrice: 115, // TODO: remoe this from tiled                             
                        category,                        
                        manufacturer: 'Solarstone',
                        widthInTiles: 5 * 2,
                        power: 90,
                        color: 'black',                        
                    },
                    {
                        id: '893' + '-from-' + category.id,
                        name: 'Solarstone 108W',
                        code: 'STR21MO108WC22',
                        price: 115,
                        passivePrice: 115, // TODO: remoe this from tiled
                        halvedPrice: 115, // TODO: remoe this from tiled                             
                        category,                        
                        manufacturer: 'Solarstone',
                        widthInTiles: 6 * 2,
                        power: 108,
                        color: 'orange',                        
                    },                    
                ],
                panel: null,
                power: 90 / (5 * 2),
            }, 
            // ===========================================================================         
            {
                id: '804' + '-from-' + category.id,
                name: 'Teviva',
                code: 'BMI kood',
                price: 202,
                passivePrice: 202, // TODO: remoe this from tiled
                halvedPrice: 202, // TODO: remoe this from tiled                  
                category,
                manufacturer: 'BMI Monier ',
                width: 300 / 2,
                height: 335,
                color: 'black',
                previewImage: fullRoofBlackPreviewImage,
                images: [],
                compatible: [
                    {
                        id: '894' + '-from-' + category.id,
                        name: 'Solarstone 90W',
                        code: 'STR21MO90WC18',
                        price: 115,
                        passivePrice: 115, // TODO: remoe this from tiled
                        halvedPrice: 115, // TODO: remoe this from tiled                             
                        category,                        
                        manufacturer: 'Solarstone',
                        widthInTiles: 5 * 2,
                        power: 90,
                        color: 'black',                        
                    },                    
                    {
                        id: '893' + '-from-' + category.id,
                        name: 'Solarstone 108W',
                        code: 'STR21MO108WC22',
                        price: 115,
                        passivePrice: 115, // TODO: remoe this from tiled
                        halvedPrice: 115, // TODO: remoe this from tiled                             
                        category,                        
                        manufacturer: 'Solarstone',
                        widthInTiles: 6,
                        power: 108,
                        color: 'orange',                        
                    },                    
                ],
                panel: null,
                power: 90 / (5 * 2),
            }, 
            // ===========================================================================     
            {
                id: '805' + '-from-' + category.id,
                name: 'Tegalit',
                code: 'BMI kood',
                price: 202,
                passivePrice: 202, // TODO: remoe this from tiled
                halvedPrice: 202, // TODO: remoe this from tiled                  
                category,
                manufacturer: 'BMI Monier ',
                width: 300 / 2,
                height: 335,
                color: 'black',
                previewImage: fullRoofBlackPreviewImage,
                images: [],
                compatible: [
                    {
                        id: '894' + '-from-' + category.id,
                        name: 'Solarstone 90W',
                        code: 'STR21MO90WC18',
                        price: 115,
                        passivePrice: 115, // TODO: remoe this from tiled
                        halvedPrice: 115, // TODO: remoe this from tiled                          
                        category,                        
                        manufacturer: 'Solarstone',
                        widthInTiles: 5 * 2,
                        power: 90,
                        color: 'black',                        
                    },                    
                    {
                        id: '893' + '-from-' + category.id,
                        name: 'Solarstone 108W',
                        code: 'STR21MO108WC22',
                        price: 115,
                        passivePrice: 115, // TODO: remoe this from tiled
                        halvedPrice: 115, // TODO: remoe this from tiled                          
                        category,                        
                        manufacturer: 'Solarstone',
                        widthInTiles: 6 * 2,
                        power: 108,
                        color: 'orange',                        
                    },                    
                ],
                panel: null,
                power: 90 / (5 * 2),
            }, 

        );
        return result;

    } else {

        const result: SolarPanel[] = [];
        result.push(
            {
                id: '790' + '-from-' + category.id,
                name: 'Risen TitanS 390W',
                code: 'SCO-A32321.V1',
                price: 320,
                passivePrice: 240,
                halvedPrice: 120,
                category,
                manufacturer: 'Risen Energy co',
                width: 1148,
                height: 1851,
                power: 390,
                color: 'black',
                orientation: 'portrait',
                previewImage: fullRoofBlackPreviewImage,
                images: [],
            },
            {
                id: '789' + '-from-' + category.id,
                name: 'Risen TitanS 390W',
                code: 'SCO-A32311.V1',
                price: 320,
                passivePrice: 240,
                halvedPrice: 120,
                category,
                manufacturer: 'Risen Energy co',
                width: 1806,
                height: 1193,
                power: 390,
                color: 'black',
                orientation: 'landscape',
                previewImage: fullRoofBlackPreviewImage,
                images: [],
            },
            // {
            //     id: '7891' + '-from-' + category.id,
            //     name: 'Risen TitanS 190W',
            //     code: 'SCO-A32311.V1',
            //     price: 320,
            //     passivePrice: 240,
            //     halvedPrice: 120,
            //     category,
            //     manufacturer: 'Risen Energy co',
            //     width: 1806,
            //     height: 1193,
            //     power: 390,
            //     color: 'gray',
            //     orientation: 'horizontal',
            //     previewImage: fullRoofBlackPreviewImage,
            //     images: [],
            // },
            // {
            //     id: '788' + '-from-' + category.id,
            //     name: 'Risen TitanS 190W',
            //     code: 'SCO-A32311.V1',
            //     price: 333,
            //     passivePrice: 240,
            //     halvedPrice: 120,
            //     category,
            //     manufacturer: 'Risen Energy co',
            //     width: 1806,
            //     height: 1193,
            //     power: 190,
            //     color: 'orange',
            //     orientation: 'horizontal',
            //     previewImage: fullRoofBlackPreviewImage,
            //     images: [],
            // },
            // {
            //     id: '7881' + '-from-' + category.id,
            //     name: 'Risen TitanS 190W',
            //     code: 'SCO-A32311.V1',
            //     price: 333,
            //     passivePrice: 240,
            //     halvedPrice: 120,
            //     category,
            //     manufacturer: 'Risen Energy co',
            //     width: 1806,
            //     height: 1193,
            //     power: 190,
            //     color: 'yellow',
            //     orientation: 'horizontal',
            //     previewImage: fullRoofBlackPreviewImage,
            //     images: [],
            // },         
        );
        return result;
    }
    
}
