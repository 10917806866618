/* eslint-disable @typescript-eslint/no-explicit-any */
import { CellBoundary } from '@/plugins/tile-selector/types/CellBoundary';

export default {
    transform(data: any) : CellBoundary | null {
        if (!data) {
            return null;
        }

        if (!data.topLeft || !data.topRight || !data.bottomLeft || !data.bottomRight) {
            return null;
        }

        if (Number.isNaN(Number(data.topLeft.x)) || Number.isNaN(Number(data.topLeft.y))
            || Number.isNaN(Number(data.topRight.x)) || Number.isNaN(Number(data.topRight.y))
            || Number.isNaN(Number(data.bottomLeft.x)) || Number.isNaN(Number(data.bottomLeft.y))
            || Number.isNaN(Number(data.bottomRight.x)) || Number.isNaN(Number(data.bottomRight.y))) {
            return null;
        }

        return {
            topLeft: {
                x: Number(data.topLeft.x),
                y: Number(data.topLeft.y),
            },
            topRight: {
                x: Number(data.topRight.x),
                y: Number(data.topRight.y),
            },
            bottomLeft: {
                x: Number(data.bottomLeft.x),
                y: Number(data.bottomLeft.y),
            },
            bottomRight: {
                x: Number(data.bottomRight.x),
                y: Number(data.bottomRight.y),
            },
        };
    },
};
