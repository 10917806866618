import { Module } from 'vuex';
import { state } from '@/store/user/state';
import { mutations } from '@/store/user/mutations';
import { actions } from '@/store/user/actions';
import { State } from '@/store/user/types';
import { RootState } from '@/store/types';

const namespaced = true;

export const userModule: Module<State, RootState> = {
    namespaced,
    state,
    mutations,
    actions,
};