import {
    CalculationParams,
    CalculationResult,
    ITileSelectorService,
} from './ITileSelectorService';
import GridDataTransformer from '@/transformers/input/tile-selector/GridDataTransformer';
import { Grid } from '@/plugins/tile-selector/types/Grid';
import { SolarPanel, Tile } from '@/types';

export class TileSelectorService implements ITileSelectorService {
    apiURL = 'https://p01--ss-backend--8b99jtl4bvsp.code.run';
    //apiURL = 'http://localhost:3000';

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public async calculateGridData(
        params: CalculationParams,
    ): Promise<CalculationResult> {
        let transformedGrid: Grid | null = null;

        const response = await fetch(
            //this.apiURL + '/api/tile-calculator/tiled-grid',
            this.apiURL + '/api/tile-calculator/grid',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: params.product.id,
                    coordsFlat: params.roofSegment.coordsFlat,
                    solarPanel: {
                        manufacturer: (params.product as SolarPanel).manufacturer,
                        width: (params.product as SolarPanel).width,
                        height: (params.product as SolarPanel).height,
                        power: (params.product as SolarPanel).power,
                        orientation: (params.product as SolarPanel).orientation,
                    },
                }),
            },
        );

        // {id: 'hash', grid: {rows, columns, cells}}
        const result = await response?.json();

        const tilesPerPanel = (params.product.category.slug == 'tiled-roof') ? (params.product as Tile).compatible[0].widthInTiles : 0;

        transformedGrid = GridDataTransformer.transform({
            ...result?.grid,
            cellWidth: (params.product as SolarPanel).width,
            cellHeight: (params.product as SolarPanel).height,
            tiledBy: tilesPerPanel,
        });

        if (!transformedGrid)
            throw new Error('Grid data has not been obtained');

        // let maxNrOfSolarPanelsPerSegment = 0;

        // transformedGrid.cells.forEach((row) => {
        //     row.forEach((cell) => {
        //         maxNrOfSolarPanelsPerSegment +=
        //             cell.state.name == 'active' ? 1 : 0;
        //     });
        // });            
        
        // console.log('maxNrOfSolarPanelsPerSegment =  ', maxNrOfSolarPanelsPerSegment);
        return {
            grid: {
                ...transformedGrid,
                cellWidth: (params.product as SolarPanel).width,
                cellHeight: (params.product as SolarPanel).height,
                tiledBy: tilesPerPanel,
            },
            polygon: result.polygon,
        };
    }
}
