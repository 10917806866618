/* eslint-disable @typescript-eslint/no-explicit-any */
import { Cell } from '@/plugins/tile-selector/types/Cell';
import CellStateTransformer from '@/transformers/input/tile-selector/CellStateTransformer';
import PointTransformer from '@/transformers/input/tile-selector/PointTransformer';
import CellDimensionsTransformer from '@/transformers/input/tile-selector/CellDimensionsTransformer';
import CellBoundaryTransformer from './CellBoundaryTransformer';
import { ActiveState, NoPanelState } from '@/plugins/tile-selector/states';
import * as CellStates from '@/plugins/tile-selector/states';

const ALLOWED_CELL_TYPES = ['interactive', 'nonInteractive'];

export default {
    transform(data: any) : Cell | null {
        if (!data || typeof data !== 'object') {
            return null;
        }

        if (Number.isNaN(Number(data.row)) || data.row < 0) {
            return null;
        }

        if (Number.isNaN(Number(data.column)) || data.column < 0) {
            return null;
        }

        // if (!ALLOWED_CELL_TYPES.includes(data.type)) {
        //     console.log('err 4');
        //     return null;
        // }

        const transformedCellState = CellStateTransformer.transform(data.state);
        if (!transformedCellState) {
            console.log('err 5');
            // TODO: return null;
        }

        const transformedCellPosition = PointTransformer.transform(data.position);
        if (!transformedCellPosition) {
            return null;
        }

        const transformedCellDimensions = CellDimensionsTransformer.transform(data.dimensions);
        if (!transformedCellDimensions) {
            return null;
        }

        const transformedCellBoundary = CellBoundaryTransformer.transform(data.boundary);
        if (!transformedCellBoundary) {
            return null;
        }

        let typeOfInteraction = data.type;
        switch (transformedCellState?.name) {
        case CellStates.PassiveState.name: typeOfInteraction = 'intersection'; break;
        case CellStates.HalvedEast.name: typeOfInteraction = 'intersectionEast'; break;
        case CellStates.HalvedNorth.name: typeOfInteraction = 'intersectionNorth'; break;
        case CellStates.HalvedSouth.name: typeOfInteraction = 'intersectionSouth'; break;
        case CellStates.HalvedWest.name: typeOfInteraction = 'intersectionWest'; break;
        case CellStates.Corner10AM.name: typeOfInteraction = 'intersection10AM'; break;
        case CellStates.Corner13PM.name: typeOfInteraction = 'intersection13PM'; break;
        case CellStates.Corner17PM.name: typeOfInteraction = 'intersection17PM'; break;
        case CellStates.Corner7AM.name: typeOfInteraction = 'intersection7AM'; break;
        default: typeOfInteraction = 'interactive'; break;
        }

        return {
            row: Number(data.row),
            column: Number(data.column),
            state: transformedCellState ? transformedCellState : (data.interactive ? ActiveState : NoPanelState), // TODO: remove this hack
            type: data.interactive ? typeOfInteraction : 'nonInteractive',
            position: transformedCellPosition,
            dimensions:transformedCellDimensions,
            boundary: transformedCellBoundary,
        };
    },
};
