import { ActionTree } from 'vuex';
import { State, UpdateProductParams } from './types';
import { RootState } from '../types';
import { CartItem, CartItemData, Product } from '@/types';

export const actions: ActionTree<State, RootState> = {
    resetCart({ commit }) {
        commit('RESET_CART');
    },
    addToCart({ commit }, params: CartItemData) {
        if (!params.product || !params.category) {
            return;
        }

        commit('ADD_TO_CART', params);
    },
    deleteCartItem({ commit }, cartItem: CartItem) {
        if (!cartItem) {
            return;
        }

        commit('DELETE_CART_ITEM', cartItem);
    },
    updateProduct({ commit }, params: UpdateProductParams) {
        if (!params.product || !params.cartItem) {
            return;
        }

        commit('UPDATE_PRODUCT', params);
    },
    updateCartItem({ commit }, product: Product) {
        if (!product) {
            return;
        }

        commit('UPDATE_CART_ITEM', product);
    },
};
