import { faker } from '@faker-js/faker';
import { Category } from '@/types';

const roofCategory: Category = {
    id: '123',
    slug: 'solar-roofs',
    name: 'Solar Roofs',
    ancestors: [],
    parent: null,
    description: '',
    previewImage: '',
    images: [],
};

const wallCategory: Category = {
    id: '234',
    slug: 'solar-walls',
    name: 'Solar Walls',
    ancestors: [],
    parent: null,
    description: '',
    previewImage: '',
    images: [],
};

const fullRoofDescription =
    'This is it! Your most cost-effective building-integrated solar solution on the market.' +
    'Supported by patented Click-on technology, the Solar Full Roof solution is the most ' +
    'efficient method to incorporate top solar products into your roof while preserving ' +
    'the aesthetics of your beloved home.';
const fullRoofPreviewImagePath =
    'https://p01--ss-backend--8b99jtl4bvsp.code.run/uploads/full-roof.png';

export const categories: Category[] = [
    {
        id: '456',
        slug: 'full-roof',
        name: 'Solar Full Roof™',
        ancestors: [roofCategory],
        parent: roofCategory,
        description: fullRoofDescription,
        previewImage: require('../../assets/images/full-roof.png'),
        images: roofCategory.images,
    },
    /* 
    {
        id: '458',
        slug: 'facade-roof',
        name: 'Facade Roof',
        ancestors: [wallCategory],
        parent: wallCategory,
        description: faker.commerce.productDescription(),
        previewImage: require('../../assets/images/facades.png'),
        images: wallCategory.images,
    },
     */
    {
        id: '457',
        slug: 'tiled-roof',
        name: 'Solar Tiled Roof™',
        ancestors: [roofCategory],
        parent: roofCategory,
        description: faker.commerce.productDescription(),
        previewImage: require('../../assets/images/tiled-roof.png'),
        images: roofCategory.images,
    },
];
