import {
    EnergyProductionInfo,
    EnergyProductionResponse,
    GridDataResponse,
    SolarPanel,
    Tile,
} from '@/types';
import { RawData } from '@/types/energy-production/RawData';


import { fitPanels } from '@/utils';

export default {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(data: RawData): EnergyProductionInfo | null {
        if (!data) return null;

        if (
            !data.energyProductionByCoordinates ||
            !data.gridData ||
            !data.product
        )
            return null;

        const product: SolarPanel | Tile = data.product;

        const tilesPerPanel = (product.category.slug == 'tiled-roof') ? (<unknown>product as Tile).compatible[0].widthInTiles : 0;

        // energyProductionByCoordinates data is retrieved per segment
        const energyProductionByCoordinates: EnergyProductionResponse[] =
            data.energyProductionByCoordinates;

        // response property will be 'null' if wrong input to API
        if (
            !energyProductionByCoordinates.length ||
            !!energyProductionByCoordinates.find(
                (energyProductionData) => !energyProductionData.response,
            )
        ) {
            console.error(
                'Unable to get Energy Production data in [EnergyProductionTransformer]',
            );
            return null;
        }

        // check EnergyProductionResponse structure for first segment
        if (
            energyProductionByCoordinates[0]?.response?.outputs?.monthly
                ?.fixed[0]?.E_m === undefined ||
            energyProductionByCoordinates[0]?.response?.outputs?.monthly
                ?.fixed[0]?.month === undefined ||
            energyProductionByCoordinates[0]?.response?.outputs?.monthly?.fixed
                .length !== 12
        ) {
            //
            console.error('Energy Production data has incorrect structure');
        }

        // gridData is retrieved per segment
        const gridData: GridDataResponse[] = data.gridData;

        if (!gridData.length) {
            console.error(
                'Unable to get grid data in [EnergyProductionService]',
            );
        }

        // check GridDataResponse structure for first segment
        if (gridData[0]?.id === undefined || gridData[0]?.grid === undefined) {
            console.error('Grid data has incorrect structure');
        }
        if (gridData[0].grid?.cells[0][0]?.state?.name === undefined) {
            console.error('Grid data is missing a state name');
        }

        let energyProductionData: EnergyProductionInfo | null = null;
        let maxNrOfSolarPanelsPerSegment = 0;
        let energyProductionPerOneSegment: {
            month: number;
            E_m: number;
        }[] = [];
        const powerPerSegment: {
            segmentId: number;
            power: number;
        }[] = [];
        const energyProductionPerSegmentByMonth: {
            segment: number; // TODO: currently this is iteration number, maybe need segment id from BE
            production: { month: number; E_m: number }[];
        }[] = [];
        type productionByMonthKey =
            keyof typeof wholeRoofEnergyProductionByMonth;

        const wholeRoofEnergyProductionByMonth = {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
        };

        // count all the states of cell status = 'active' on grid
        gridData.forEach((segment) => {
            // clear before switching to new segment
            maxNrOfSolarPanelsPerSegment = 0;
            let rowCounter = 0;
            segment.grid.cells.forEach((row) => {

                if (tilesPerPanel) { // second lowest row should have no panels
                    fitPanels(tilesPerPanel, row, rowCounter == (segment.grid.cells.length - 2 ));

                } 

                rowCounter++;                    

                row.forEach((cell) => {
                    maxNrOfSolarPanelsPerSegment +=
                        cell.state.name == 'active' ? 1 : 0;
                });
                
            });

            // compile power of roof segment
            powerPerSegment.push({
                segmentId: Number(segment.id),
                power: maxNrOfSolarPanelsPerSegment * product.power,
            });
        });

        // Multiply monthly pvgis energy production data by power of roof segment (powerPerSegment)
        energyProductionByCoordinates.forEach(
            (segment: EnergyProductionResponse, i) => {
                // clear before switching segment
                energyProductionPerOneSegment = [];

                segment.response.outputs.monthly.fixed.forEach((month) => {
                    energyProductionPerOneSegment.push({
                        E_m: month.E_m * powerPerSegment[i].power,
                        month: month.month,
                    });
                });

                energyProductionPerSegmentByMonth.push({
                    segment: i, // TODO maybe need segment id from BE
                    production: energyProductionPerOneSegment,
                });
            },
        );

        // add up roof segment energy production by each month
        energyProductionPerSegmentByMonth.forEach((segment) => {
            segment.production?.forEach((byMonth) => {
                const numberOfMonth = byMonth.month as productionByMonthKey;
                wholeRoofEnergyProductionByMonth[numberOfMonth] += byMonth.E_m;
            });
        });

        const remappedEnergyProduction = Object.entries(
            wholeRoofEnergyProductionByMonth,
        ).map(
            (entry) => (
                // divide E_m (energy production monthly) by 1000 to get kW
                entry[0],
                {
                    month: Number(entry[0]),
                    E_m: Number((entry[1] / 1000).toFixed(2)),
                }
            ),
        );

        energyProductionData = {
            energyProductionByMonth: remappedEnergyProduction,
            energyProductionFullYear: Number(
                remappedEnergyProduction
                    .reduce((acc, curr) => acc + curr.E_m, 0)
                    .toFixed(),
            ),
        };

        return {
            ...energyProductionData,
        };
    },
};
