import { MutationTree } from 'vuex';
import { State } from './types';
import { Grid } from '@/plugins/tile-selector/types/Grid';
import { RawData } from '@/types/energy-production/RawData';
import { EnergyProductionInfo } from '@/types';

export const mutations: MutationTree<State> = {
    SET_RAW_PRODUCTION(state: State, data) {
        state.rawData = data;
    },
    SET_PRODUCTION_CALCULATION(state: State, data) {
        state.production = data;
    },
    UPDATE_PRODUCTION_GRID(
        state: State,
        data: { currentGrid: Grid; segmentId: number },
    ) {

        state.rawData.gridData.forEach(({ grid, id }) => {
            // update only the currently selected segmentId
            if (data.segmentId === id) {
                grid.cells = JSON.parse(JSON.stringify(data.currentGrid.cells));
                grid.freezeWatcher = !grid.freezeWatcher;
            }
        });

    },
    RESET_RAW_PRODUCTION(state: State) {
        state.rawData = {} as RawData;
    },
    RESET_PRODUCTION(state: State) {
        state.production = {} as EnergyProductionInfo;
    },
};
