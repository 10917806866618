import { CellState } from '../types/CellState';

export const ActiveState: CellState = Object.freeze({
    name: 'active',
    appearance: {
        fillColor: '#FEEDDA',
        borderColor: '#F98A08',
    },
});

export const PassiveState: CellState = Object.freeze({
    name: 'passive',
    appearance: {
        fillColor: '#F3F5F8',
        borderColor: '#DEE2ED',
    },
});

export const HalvedNorth: CellState = Object.freeze({
    name: 'halvedNorth',
    appearance: {
        fillColor: '#F3F5F8',
        borderColor: '#DEE2ED',
    },
});

export const HalvedEast: CellState = Object.freeze({
    name: 'halvedEast',
    appearance: {
        fillColor: '#F3F5F8',
        borderColor: '#DEE2ED',
    },
});

export const HalvedSouth: CellState = Object.freeze({
    name: 'halvedSouth',
    appearance: {
        fillColor: '#F3F5F8',
        borderColor: '#DEE2ED',
    },
});

export const HalvedWest: CellState = Object.freeze({
    name: 'halvedWest',
    appearance: {
        fillColor: '#F3F5F8',
        borderColor: '#DEE2ED',
    },
});

export const Corner10AM: CellState = Object.freeze({
    name: 'corner10AM',
    appearance: {
        fillColor: '#F3F5F8',
        borderColor: '#DEE2ED',
    },
});
export const Corner13PM: CellState = Object.freeze({
    name: 'corner13PM',
    appearance: {
        fillColor: '#F3F5F8',
        borderColor: '#DEE2ED',
    },
});
export const Corner17PM: CellState = Object.freeze({
    name: 'corner17PM',
    appearance: {
        fillColor: '#F3F5F8',
        borderColor: '#DEE2ED',
    },
});
export const Corner7AM: CellState = Object.freeze({
    name: 'corner7AM',
    appearance: {
        fillColor: '#F3F5F8',
        borderColor: '#DEE2ED',
    },
});


export const NoPanelState: CellState = Object.freeze({
    name: 'noPanel',
    appearance: {
        fillColor: '#DEE2ED',
        borderColor: '#F3F5F8',
    },
});
