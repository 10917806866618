import { State } from './types';

export const state: State = {
    buildingData: {
        cartId: '',
        totalArea3DM2: 0,
        totalProductivityKwhPerYear: 0,
        totalInCartArea3DM2: 0,
        totalInCartProductivityKwhPerYear: 0,
        lat: 0,
        lng: 0,
        address: '',
        changed: false,
        roofSegments: [],
    },
    address: {
        placeId: '1',
        name: 'Suur Gerbera tee 11, 74001, Viimsi', // Set to empty later
        lat: 59.4452105, // Set this to 0 later
        lng: 24.74169, // Set this to 0 later
        formattedAddress: '',
        streetNumber: '',
        streetName: '',
        locality: '',
        sublocality: '',
        regionLv1: '',
        country: '',
        postalCode: '',
    },
};
