/* eslint-disable @typescript-eslint/no-explicit-any */
import { CellDimensions } from '@/plugins/tile-selector/types/CellDimensions';

export default {
    transform(data: any) : CellDimensions | null {
        if (!data) {
            return null;
        }

        if (!data.width || !data.height) {
            return null;
        }

        if (Number.isNaN(Number(data.width)) || Number.isNaN(Number(data.height))) {
            return null;
        }

        return {
            width: Number(data.width),
            height: Number(data.height),
        };
    },
};
