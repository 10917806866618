import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        redirect: 'plan',
        component: () => import('@/App.vue'),
        children: [
            {
                path: '/plan',
                name: 'plan',
                component: () => import('../views/plans/PlanSelector.vue'),
            },
            {
                path: '/plan/have-building',
                name: 'have-building',
                component: () => import('../views/plans/HaveBuilding.vue'),
            },
            {
                path: '/plan/have-idea',
                name: 'have-idea',
                component: () => import('../views/plans/HaveIdea.vue'),
            },
            {
                path: '/product-selector',
                name: 'product-selector',
                component: () => import('../views/product-selector/ProductSelector.vue'),
            },
            {
                path: '/map',
                name: 'map',
                component: () => import('../views/map/MapView.vue'),
            },
            {
                path: '/cart',
                name: 'cart',
                component: () => import('../views/components/CartView.vue'),
            },
            {
                path: '/calculator',
                name: 'calculator-view',
                component: () => import('../views/calculator/CalculatorView.vue'),
            },
            {
                path: '/summary',
                name: 'summary-view',
                component: () => import('../views/summary/SummaryView.vue'),
            },
            {
                path: '/about',
                name: 'about',
                component: () => import('../views/AboutView.vue'),
            },
        ],
    },
    {
        path: '/components',
        name: 'components',
        component: () => import('../views/components/ComponentList.vue'),
        children: [
            {
                path: '/components/button',
                name: 'button',
                component: () => import('../views/components/ButtonView.vue'),
            },
            {
                path: '/components/checkbox',
                name: 'checkbox',
                component: () => import('../views/components/CheckboxView.vue'),
            },
            {
                path: '/components/radio-button',
                name: 'radio-button',
                component: () => import('../views/components/RadioButtonView.vue'),
            },
            {
                path: '/components/radio-group',
                name: 'radio-group',
                component: () => import('../views/components/RadioGroupView.vue'),
            },
            {
                path: '/components/textarea',
                name: 'textarea',
                component: () => import('../views/components/TextareaFieldView.vue'),
            },
            {
                path: '/components/select',
                name: 'select',
                component: () => import('../views/components/SelectView.vue'),
            },
            {
                path: '/components/input',
                name: 'input',
                component: () => import('../views/components/InputFieldView.vue'),
            },
            {
                path: '/components/tooltip',
                name: 'tooltip',
                component: () => import('../views/components/TooltipView.vue'),
            },
            {
                path: '/components/modal',
                name: 'modal',
                component: () => import('../views/components/ModalView.vue'),
            },
            {
                path: '/components/info-window',
                name: 'info-window',
                component: () => import('../views/components/InfoWindowView.vue'),
            },
            {
                path: '/components/user-guide',
                name: 'user-guide',
                component: () => import('../views/components/UserGuideView.vue'),
            },
            {
                path: '/components/custom-scrollbar',
                name: 'custom-scrollbar',
                component: () => import('../views/components/CustomScrollbarView.vue'),
            },
            {
                path: '/components/address-autocomplete',
                name: 'address-autocomplete',
                component: () => import('../views/components/AddressAutocompleteView.vue'),
            },
            {
                path: '/components/modal/cancel-calculation',
                name: 'cancel-calculation-modal',
                component: () => import('../views/components/CancelCalculationModalView.vue'),
            },
            {
                path: '/components/modal/enter-user-data',
                name: 'enter-user-data-modal',
                component: () => import('../views/components/EnterUserDataModalView.vue'),
            },
            {
                path: '/components/modal/reset-changes',
                name: 'reset-changes-modal',
                component: () => import('../views/components/ResetChangesModalView.vue'),
            },
        ],
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: { name: 'plan' },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
