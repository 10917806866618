import { MutationTree } from 'vuex';
import { CartItemData, CartItem, Product } from '@/types';
import { State, UpdateProductParams } from './types';

export const mutations: MutationTree<State> = {
    RESET_CART(state: State) {
        state.cartItems.splice(0,state.cartItems.length);
    },
    ADD_TO_CART(state: State, data: CartItemData) {
        if (!data.product || !data.category) {
            return;
        }

        const cartItem: CartItem = {
            id: Math.random().toString(36).slice(2),
            category: data.category,
            product: data.product,
            quantity: 1,
            price: data.price,
        };

        const isProductInCart = !!state.cartItems.find((item: CartItem) => item.product.id === cartItem.product.id);

        const isCategoryInCart = !!state.cartItems.find((item: CartItem) => item.category.parent?.id === cartItem.category.parent?.id);

        if(!isProductInCart){
            if(isCategoryInCart){
                state.cartItems = state.cartItems.filter((item: CartItem) => item.category.parent?.id !== cartItem.category.parent?.id);
            }
            state.cartItems.push(cartItem);
        }
    },
    DELETE_CART_ITEM(state: State, cartItem: CartItem) {
        if (!cartItem) {
            return;
        }

        const index = state.cartItems.findIndex((item: CartItem) => item.product.id === cartItem.product.id);
        if(index < 0) {
            return;
        } else {
            state.cartItems.splice(index, 1);
        }
    },
    UPDATE_PRODUCT(state: State, params: UpdateProductParams) {
        if (!params.product || !params.cartItem) {
            return;
        }

        const item = state.cartItems.find((item: CartItem) => item.id === params.cartItem.id);

        if (!item) {
            return;
        }

        item.product = params.product;
        item.category = params.product.category;
        item.price = params.product.price;
    },
    UPDATE_CART_ITEM(state: State, product: Product) {
        if (!product) {
            return;
        }

        const item = state.cartItems.find((item: CartItem) => item.category.id === product.category.id);

        if (!item) {
            return;
        }

        item.product = product;
        item.price = product.price;
    },
};
