import { ActionTree, Commit, ActionContext } from 'vuex';
import { State } from '@/store/user/types';
import { RootState } from '@/store/types';

export const actions: ActionTree<State, RootState> = {
    setEmail({ commit }: { commit: Commit }, email: string) {
        commit('SET_EMAIL', email);
    },
    setPhone({ commit }: { commit: Commit }, phone: string) {
        commit('SET_PHONE', phone);
    },
    setFirstName({ commit }: ActionContext<State, RootState>, firstName: string) {
        commit('SET_FIRST_NAME', firstName);
    },
    setLastName({ commit }: ActionContext<State, RootState>, lastName: string) {
        commit('SET_LAST_NAME', lastName);
    },
    setBetaDisclaimer({ commit }: { commit: Commit }, betaDisclaimer: boolean) {
        commit('SET_BETA_DISCLAIMER', betaDisclaimer);
    },
    resetAll({ commit }) {
        commit('RESET_USER_STATE');
    },
    async resetAllStates(context: ActionContext<State, RootState>) {
        await context.dispatch('cartModule/resetCart', null, { root: true });
        await context.dispatch('buildingModule/resetAll', null, { root: true });
        await context.dispatch('solarPanelsModule/resetAll', null, { root: true });
        await context.dispatch('categoriesModule/resetAll', null, { root: true });
        await context.dispatch('projectConfiguration/resetData', null, { root: true });
        await context.dispatch('resetAll');
    },
};
