/* eslint-disable @typescript-eslint/no-explicit-any */
import { RoofSegment, BuildingData } from '@/types';
import { Address } from '@/types';

export class MapService {
    private static instance: MapService | null = null;

    private iframe: HTMLIFrameElement | null = null;

    public static getInstance(): MapService {
        if (!MapService.instance) {
            MapService.instance = new MapService();
        }
        return MapService.instance;
    }

    public setIframeInstance(iframeInstance: HTMLIFrameElement) {
        this.iframe = iframeInstance;
    }

    public locateBuilding(data: Address) {
        if (!this.iframe || !this.iframe.contentWindow) {
            return;
        }

        if (!data.placeId || !data.lat || !data.lng) {
            return;
        }

        this.iframe.contentWindow.postMessage({ type: 'goTo', lat: data.lat, lng: data.lng, closest: true }, '*');
    }

    public highlightRoofSegment(data: RoofSegment) {
        if (!this.iframe || !this.iframe.contentWindow) {
            return;
        }
        this.iframe.contentWindow.postMessage({ type: 'selectSegment', segmentId: data.id }, '*');
    }

    public unhighlightRoofSegment(data: RoofSegment) {
        if (!this.iframe || !this.iframe.contentWindow) {
            return;
        }
        this.iframe.contentWindow.postMessage({ type: 'deselectSegment', segmentId: data.id }, '*');
    }

    public selectRoofSegment(roofSegment: RoofSegment) {
        if (!this.iframe || !this.iframe.contentWindow) {
            return;
        }
        // this.save(buildingData);
        this.iframe.contentWindow.postMessage({ type: 'addToCart', segmentIds: [roofSegment.id] }, '*');
    }

    public deselectRoofSegment(roofSegment: RoofSegment) {
        if (!this.iframe || !this.iframe.contentWindow) {
            return;
        }
        // this.save(buildingData);
        this.iframe.contentWindow.postMessage({ type: 'removeFromCart', segmentIds: [roofSegment.id] }, '*');
    }

    public reset() {
        if (!this.iframe || !this.iframe.contentWindow) {
            return;
        }
        this.iframe.contentWindow.postMessage({ type: 'reset' }, '*');
    }

    public save(buildingData: BuildingData) {
        if (!buildingData.cartId) {
            return;
        }
        if (!this.iframe || !this.iframe.contentWindow) {
            return;
        }
        this.iframe.contentWindow.postMessage({ type: 'save', cartId: buildingData.cartId }, '*');
    }

    public loadCart(buildingData: BuildingData) {
        if (!buildingData.cartId) {
            return;
        }
        if (!this.iframe || !this.iframe.contentWindow) {
            return;
        }
        this.iframe.contentWindow.postMessage({ type: 'loadCart', cartId: buildingData.cartId }, '*');
    }

    public toggleIcons() {
        if (!this.iframe || !this.iframe.contentWindow) {
            return;
        }
        this.iframe.contentWindow.postMessage({ type: 'toggleSearchBar' }, '*');
        this.iframe.contentWindow.postMessage({ type: 'toggleIcons' }, '*');
    }
}
