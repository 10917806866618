import { createStore } from 'vuex';
import { cartModule } from './cart';
import { buildingModule } from './buildingModule';
import { userModule } from '@/store/user';
import { categoriesModule } from '@/store/categories';
import { solarPanelsModule } from '@/store/solar-panels';
import { projectConfiguration } from '@/store/tile-selector';
import { energyProductionModule } from './energyProduction';

export default createStore({
    modules: {
        cartModule,
        buildingModule,
        userModule,
        categoriesModule,
        solarPanelsModule,
        projectConfiguration,
        energyProductionModule,
    },
});
