import {
    RoofSegment,
} from '@/types';
import { RawData } from '@/types/energy-production/RawData';

export default {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(data: { rawData: RawData; segmentsInCart: RoofSegment[] }): RawData | null {
        let rawData: RawData | null = null;
        
        // check structure
        if (!data?.rawData?.energyProductionByCoordinates?.length || !data?.rawData?.gridData?.length) {
            return null;
        }

        const segmentsInCart = data.segmentsInCart;
        const indexesNotInCart: { roofSegmentId: number; index: number }[] =
            [];

        // filter out all gridData which don't have a corresponding segment in cart
        // i.e. keep gridData whose segment is in cart (selected)
        const filteredGridData = data.rawData.gridData.filter(({id}, i) => {
            const isInCart = !!segmentsInCart.find((segment) => id === segment.id);
            if (!isInCart)
                indexesNotInCart.push({ roofSegmentId: id, index: i });
            return isInCart;
        });

        // keep pvgis data whose indexes have corresponding segment in cart
        const filteredEPByCoordinates = indexesNotInCart.length ? data.rawData.energyProductionByCoordinates.filter((item, i) => {
            const isFilteredOutIndex = !!indexesNotInCart.find((item) => i === item.index);
            return !isFilteredOutIndex;
        }) : data.rawData.energyProductionByCoordinates;

        if (filteredEPByCoordinates.length && filteredGridData.length)
            rawData = {
                energyProductionByCoordinates: filteredEPByCoordinates,
                gridData: filteredGridData,
                product: data.rawData.product,
            };

        return rawData;
    },
};