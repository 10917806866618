import { IEnergyProductionService } from './IEnergyProductionService';
import EnergyProductionTransformer from '@/transformers/input/energy-production-data/EnergyProductionTransformer';
import RoofSegmentDataTransformer from '@/transformers/input/energy-production-data/RoofSegmentDataTransformer';
import { EnergyProductionInfo, RoofSegment, SolarPanel } from '@/types';
import { RawData } from '@/types/energy-production/RawData';

export class EnergyProductionService implements IEnergyProductionService {
    apiURL = 'https://p01--ss-backend--8b99jtl4bvsp.code.run';
    //apiURL = 'http://localhost:3000';
    public async getRawData({
        segments,
        product,
    }: {
        segments: RoofSegment[];
        product: SolarPanel;
    }): Promise<{
        energyProductionByCoordinates: unknown;
        gridData: unknown;
        product: SolarPanel;
    } | null> {
        let transformedSegments: RoofSegment[] | null = [];
        transformedSegments = RoofSegmentDataTransformer.transform(segments);

        if (!transformedSegments || !product?.power) return null;

        const segmentsPossibleToEnable = transformedSegments.filter(
            (segment) => !segment.disabled,
        );

        let rawData = null;

        try {
            // get monthly fixed panel energy production data (by 1kW BE side)
            const requestEnergyProduction = await fetch(
                this.apiURL + '/api/pv-calculator/segments',
                {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        segments: segmentsPossibleToEnable.map((segment) => ({
                            coordsFlat: [
                                segment.geometry[0][1],
                                segment.geometry[0][0],
                            ],
                            tiltAngle: segment.tilt,
                            azimuth: segment.azimuth - 180,
                            loss: 20, // TODO: loss is fixed value
                        })),
                    }),
                },
            );

            // get grid data to count maxNrOfSolarPanelsPerSegment
            const requestGrid = await fetch(
                this.apiURL + '/api/array-calculator/gridArray',
                {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        segments: segmentsPossibleToEnable.map((segment) => ({
                            id: segment.id,
                            coordsFlat: segment.coordsFlat,
                            solarPanel: {
                                // TODO: only one product possible?
                                manufacturer: product.manufacturer,
                                width: product.width,
                                height: product.height,
                                power: product.power,
                                orientation: product.orientation,
                            },
                        })),
                    }),
                },
            );

            const energyProductionByCoordinates =
                await requestEnergyProduction?.json();
            const gridData = await requestGrid?.json();

            if (!energyProductionByCoordinates || !gridData)
                throw new Error(
                    'Energy production data could not be retrieved.',
                );

            rawData = {
                energyProductionByCoordinates,
                gridData,
                product,
            };
        } catch (error) {
            console.error('Network error in [EnergyProductionService]:', error);
        }

        return rawData;
    }

    public calculate(params: RawData): EnergyProductionInfo | null {
        let energyProductionData: EnergyProductionInfo | null = null;

        energyProductionData = EnergyProductionTransformer.transform(params);

        if (!energyProductionData)
            // can't throw error since this method is used for recalculation
            // and the input data can be empty
            console.error('Energy production data is missing or faulty');

        return energyProductionData;
    }
}
