/* eslint-disable @typescript-eslint/no-explicit-any */
import { CellState } from '@/plugins/tile-selector/types/CellState';
import { ActiveState, PassiveState, NoPanelState, HalvedNorth,  HalvedEast, HalvedSouth, HalvedWest, Corner10AM, Corner13PM, Corner17PM, Corner7AM} from '@/plugins/tile-selector/states';

const ALLOWED_STATES = ['active', 'passive', 'noPanel', 'halvedNorth', 'halvedEast', 'halvedSouth', 'halvedWest', 'corner10AM', 'corner13PM', 'corner17PM', 'corner7AM'];

export default {
    transform(data: any) : CellState | null {
        if (!data) {
            return null;
        }

        if (!data.name || !data.appearance) {
            return null;
        }

        if (!ALLOWED_STATES.includes(data.name)) {
            return null;
        }

        let state: CellState;

        switch (data.name) {
        case 'active':
            state = ActiveState;
            break;
        case 'passive':
            state = PassiveState;
            break;
        case 'halvedNorth':
            state = HalvedNorth;
            break;
        case 'halvedEast':
            state = HalvedEast;
            break;
        case 'halvedSouth':
            state = HalvedSouth;
            break;
        case 'halvedWest':
            state = HalvedWest;
            break;
        case 'corner10AM':
            state = Corner10AM;
            break;
        case 'corner13PM':
            state = Corner13PM;
            break;
        case 'corner17PM':
            state = Corner17PM;
            break;
        case 'corner7AM':
            state = Corner7AM;
            break;                                    
        case 'noPanel':
            state = NoPanelState;
            break;
        default:
            state = PassiveState;
            break;
        }

        return {
            name: state.name,
            appearance: data.appearance,
        };
    },
};
