import { createI18n, LocaleMessages, VueMessageType } from 'vue-i18n';

let langLocale = localStorage.getItem('locale');
if (!langLocale) {
    langLocale = 'et';
    localStorage.setItem('locale', langLocale);
}

type LoadedLocales = { [x: string]: LocaleMessages<VueMessageType>; };

function loadLocaleMessages(): LoadedLocales {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages: LoadedLocales = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });

    return messages;
}

export default createI18n({
    locale: langLocale,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: loadLocaleMessages(),
});
