import { GetterTree } from 'vuex';
import { State } from './types';
import { RootState } from '../types';
import { SolarPanel } from '@/types';


export const getters: GetterTree<State, RootState> = {
    cartItems: (state: State) => state.cartItems,
    productivityPerYear: () => {
        //todo - add calculations
        return 9.125 + ' kW';
    },
    producePerYear: () => {
        //todo - add calculations
        return '~ ' + 2000 + ' €';
    },
    possiblePayback: () => {
        //todo - add calculations
        return 6;
    },
    solarSystemPrice: (state: State) => {
        return state.cartItems.reduce((total, item) => total + item.price, 0);
    },
    complimentaryProductTotal: () => {
        //todo - add calculations
        return 1400;
    },
    totalPowerOfProducts: (state: State) => {
        if (state.cartItems.length && (state.cartItems[0].product as SolarPanel).power) {
            return state.cartItems.reduce((total, item) => total + (<SolarPanel>item.product).power, 0);
        } else {
            // NOT A SOLAR PANEL
            return 0;
        }
    },
};
