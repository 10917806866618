/* eslint-disable @typescript-eslint/no-explicit-any */
import { RoofSegment } from '@/types';

export default {
    transform(data: any): RoofSegment[] | null {
        let segments: RoofSegment[] = [];

        if (!data || !Array.isArray(data)) {
            return null;
        }

        segments = [...data];

        // check the structure of first segment

        if (
            segments[0].id === undefined ||
            segments[0]?.geometry === undefined ||
            segments[0]?.area3DM2 === undefined ||
            segments[0]?.productivityKwhPerYear === undefined ||
            segments[0]?.area2DM2 === undefined ||
            segments[0]?.azimuth === undefined ||
            segments[0]?.surfacesMinHeight === undefined ||
            segments[0]?.buildingHeight === undefined ||
            segments[0]?.productivityPerM2 === undefined ||
            segments[0]?.polyMaxHeight === undefined ||
            segments[0]?.surfacesMaxHeight === undefined ||
            segments[0]?.tilt === undefined ||
            segments[0]?.groundHeight === undefined ||
            segments[0]?.inCart === undefined ||
            segments[0]?.invalid === undefined ||
            segments[0]?.selected === undefined ||
            segments[0]?.coordsFlat === undefined ||
            segments[0]?.disabled === undefined
        ) {
            return null;
        }

        // sometimes the map service starts counting the segment's id from 0 (zero)
        if (segments[0].id === null || Number.isNaN(Number(segments[0].id))) {
            return null;
        }

        // tilt of 90 is vertical wall
        if (Number.isNaN(Number(segments[0].tilt)) || segments[0].tilt > 100) {
            return null;
        }

        // azimuth of 90 is west. 180 is south. 270 - east(-90) TODO: accept negative?
        if (
            Number.isNaN(Number(segments[0].azimuth)) ||
            segments[0].tilt > 360
        ) {
            return null;
        }

        return segments;
    },
};
