import { MutationTree } from 'vuex';
import { BuildingData, Address } from '@/types';
import { State } from './types';

export const mutations: MutationTree<State> = {
    SET_ADDRESS(state, data: Address) {
        state.address = data;
    },
    SET_ADDRESS_FROM_MAP(state, data: BuildingData) {
        state.address.formattedAddress = data.address;
        state.address.lat = data.lat;
        state.address.lng = data.lng;
    },
    SET_BUILDING_DATA(state, data: BuildingData) {
        state.buildingData = data;
    },
    RESET_ADDRESS(state) {
        state.address = {
            placeId: '',
            name: '',
            lat: 0,
            lng: 0,
            formattedAddress: '',
            streetNumber: '',
            streetName: '',
            locality: '',
            sublocality: '',
            regionLv1: '',
            country: '',
            postalCode: '',
        };
    },
    RESET_BUILDING_DATA(state) {
        state.buildingData = {
            cartId: '',
            totalArea3DM2: 0,
            totalProductivityKwhPerYear: 0,
            totalInCartArea3DM2: 0,
            totalInCartProductivityKwhPerYear: 0,
            lat: 0,
            lng: 0,
            address: '',
            changed: false,
            roofSegments: [],
        };
    },
    SET_BUILDING_DATA_CART_ID(state) {
        state.buildingData.cartId = Math.random().toString(36).slice(2);
    },
};
